import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Card from '../components/Card'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

const Index = ({ data, pageContext }) => {
  const posts = data.allPacoGamesJson.edges
  const { currentPage } = pageContext
  const isFirstPage = currentPage === 1
  const featuredPost = isFirstPage ? posts[4] && posts[4].node : null

  return (
    <Layout>
      <SEO />
      {!isFirstPage && (
        <Helmet>
          <title>{`${config.siteTitle} - Страница ${currentPage}`}</title>
        </Helmet>
      )}
      <Container>
        <CardList>
          {featuredPost ? <Card {...featuredPost} featured /> : undefined}
          {posts.map(({ node: post }) => (
            <Card key={post.id} {...post} />
          ))}
        </CardList>
      </Container>
      <Pagination context={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allPacoGamesJson(
      sort: { fields: name, order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          name
          fields {
            slug
          }
          date
          image
          thumbnail
          translations {
            ru {
              description
            }
          }
          video {
            mp4
          }
        }
      }
    }
  }
`

export default Index
